<template>
  <div class="go-in">
    <banner img="../assets/img/bgtop.jpg" title="" />
    <div class="section" v-loading="loading">
      <div class="section-content">
        <div class="content-honor">
<!--          <div class="honor-big-img">
            <el-dialog :title="dialogTitle" :visible.sync="dialogTableVisible">
              <img v-lazy="dialogUrl" alt />
            </el-dialog>
          </div>-->
          <div class="top">
            <h3>标准化手册</h3>
            <p>Standardized manuals</p>
            <div class="border"></div>
          </div>

          <el-image style="" :src="img1"></el-image>
          <el-image style="" :src="img2"></el-image>

          <div class="top">
            <h3>标准化建造</h3>
            <p>Standardized construction</p>
            <div class="border"></div>
          </div>
          <div style="padding-left: 20px;padding-right: 10px">
            <el-button type="primary" plain style="padding: 10px 10px 10px 10px">1、集中加工车间概念</el-button>
            <p style="text-align: left;padding-top: 10px"> 利用各分项样板工程评审形成的深化图和材料清单，设立工厂化加工车间，集中加工各类标准部品，实行现场拼装式施工，同时对原材料统筹利用，杜绝浪费。集中加工是实施专业流水施工的基础和前提；</p>
          </div>
          <div style="padding-left: 20px;padding-top: 10px">
            <el-button type="primary" plain style="padding: 10px 10px 10px 10px">2、集中加工车间工作任务分解</el-button>
            <p style="text-align: left;padding-top: 10px">将集中加工的各项工作任务进行合理分解，责任到人，确定各责任人员的管理范围和工作要求，以保证集中加工各项工作任务保质保量的按时完成；</p>
          </div>
          <div style="display: flex;align-items: center;justify-content: center;padding-left: 20px;padding-right: 20px;padding-top: 10px">
            <el-table :data="tableData" border style="width: 80%">
              <el-table-column type="index" width="50"></el-table-column>
              <el-table-column prop="task" label="工作任务" ></el-table-column>
              <el-table-column prop="need" label="工作要求" ></el-table-column>
              <el-table-column prop="man" label="责任人" width="80"></el-table-column>
            </el-table>
          </div>

        </div>


      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/Banner";
export default {
  components: {
    Banner,

  },
  data() {
    return {
      tableData:[
          {task:'开展样板评审，完成施工图深化设计',need:'完成样板评审，对施工图进行深化，确定可集中加工的构件',man:'技术负责'},
          {task:'下料单，配送单',need:'根据深化图编制下料清单，班组配送领用单',man:'技术负责'},
          {task:'加工车间策划、搭设、布置',need:'根据施工需要画加工车间策划详图，专人负责加工车间的搭设和布置',man:'技术负责生产经理'},
          {task:'劳动力配备和管理责任分工',need:'配置满足需求的加工操作人员，安排专人监督和管理',man:'项目经理'},
          {task:'现场施工组织管理',need:'专人负责加工构件的现场施工管理',man:'专业工长栋号长'}
      ],
      loading: false,
      honorList: [],
      partnerImg: [],
      courseList: [],
      teamItem: [],
      swiperOption: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },
      dialogTableVisible: false,
      dialogUrl: "",
      dialogTitle: "",
      img1:require('@/assets/img/shouce1.png'),
      img2:require('@/assets/img/shouce2.png'),

    };
  },
  mounted() {

  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.go-in {
  width: 100%;
  height: 100%;
  background-color: #14679f;
  position: relative;
  overflow: hidden;
  .section {
    width: 100%;
    &-content {
      width: 1240px;
      margin: 0 auto;
      background-color: #fff;

      .content-summary {
        //height: 500px;
        display: flex;
        justify-content: space-around;
        padding: 100px 0;
        .summary-left {
          width: 600px;
          .title {
            font-size: 25px;
            color: #e13834;
          }
          .eTitle {
            font-size: 17px;
            color: #e13834;
            padding: 20px 0;
          }
          .content {
            color: #14679f;
            font-size: 14px;
            text-indent: 25px;
            line-height: 30px;
          }
        }

        .summary-right {
          width: 400px;
          height: 310px;
          border: 2px solid #1d42b9;
          //animation: imgboxkey 4s infinite;
          border-radius: 10px;
          margin-top: 80px;
          text-align: center;

          img {
            width: 360px;
            height: 270px;
            margin-top: 20px;
            //animation: imgbo 4s infinite;
          }
        }
      }

      //发展历程
      .content-course {
        padding: 50px 0;
        .course-time {
          width: 1000px;
          height: 400px;
          margin: 20px auto;
          .swiper-container {
            height: 100%;
          }
          .time-show {
            width: 700px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            .time-show-item {
              width: 350px;
              height: 100%;
              overflow: hidden;
              display: flex;
              flex-direction: column;

              .item-top,
              .item-bottom {
                height: 190px;
              }
              .item-bottom {
                // display: flex;
                // align-content: center;
                .item-bottom-content {
                  background-color: #1667a0;
                  margin: 20px 0;
                  p {
                    color: #fff;
                    text-align: center;
                    padding: 15px;
                  }
                }
              }
            }
          }
        }
      }

      //企业文化
      .content-culture {
        height: 220px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 100px;
        background-color: #c2daeb;
        h3 {
          color: #3c6088;
          font-size: 26px;
        }
        p {
          color: #3c6088;
          font-size: 26px;
          padding: 5px 0;
        }
        span {
          font-weight: 400;
          line-height: 36px;
          font-size: 18px;
          padding: 5px 0;
        }
      }

      //公司荣誉
      .content-honor {
        padding: 50px 0;
        .honor-show {
          width: 1000px;
          margin: 30px auto;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-content: flex-start;

          li {
            width: 220px;
            //height: 320px;
            margin-left: 15px;
            list-style: none;
            border: 1px solid palegoldenrod;

            img {
              width: 220px;
              height: 100%;
            }
          }
        }
        p {
          text-align: center;
          color: #3c6088;
        }
      }

      //团队风采
      .content-team {
        padding: 50px 100px;
        .swiper-img {
          height: 400px;
          background: no-repeat center;
          background-size: cover;
        }
      }

      //合作伙伴
      .content-partner {
        padding: 50px 0;
        .partner-img {
          width: 950px;
          margin: 20px auto;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          li {
            width: 107px;
            height: 107px;
            list-style: none;
            margin: 10px 25px;

            img {
              width: 100%;
              height: 100%;
              border: 1px solid rgb(194, 218, 235);
            }
          }
        }
      }
    }
  }
}

@keyframes imgboxkey {
  0% {
    border: solid rgb(29, 66, 185) 2px;
  }
  40% {
    border: solid rgb(255, 255, 255) 2px;
  }
  60% {
    border: solid rgb(255, 255, 255) 2px;
  }
  100% {
    border: solid rgb(29, 66, 185) 2px;
  }
}

@keyframes imgbo {
  0% {
    transform: scale(1);
    box-shadow: 0px 0px 0px 0px #ababab;
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0px 0px 10px 5px #ababab;
  }
  100% {
    transform: scale(1);
    box-shadow: 0px 0px 0px 0px #ababab;
  }
}
.el-divider--horizontal {
  margin: 1px 0;
}

.top {
  h3,
  p {
    text-align: center;
    color: #3c6088;
    font-weight: 400;
    padding: 10px 0;
  }
  h3 {
    font-size: 30px;
  }
  p {
    font-size: 20px;
  }
  .border {
    border-bottom: 1px solid #3c6088;
    width: 15%;
    margin: 0 auto;
  }
}
// .swiper-button-disabled {
//   display: none;
// }
.order {
  order: -1;
}
.order-top {
  order: 1;
}
.el-divider {
  background-color: red;
  height: 3px;
  .el-divider__text {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 0px;
    color: #fff;
    border: 3px solid red;
  }
}
.el-divider-active {
  background-color: #3c6088;
}
.honor-big-img {
  width: 100%;
  height: 100%;
  z-index: 10;
  text-align: center;
  padding-bottom: 20px;
  padding: 5%;
  //background-color: #14679f;
  .el-dialog__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;

    .el-dialog__body {
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }
}
.mobile {
  .section-content {
    width: auto;
    .content-summary {
      display: block;
      padding: 20px;
      .summary-left {
        width: auto;
        margin: 0 10px;
      }
      .summary-right {
        width: 100%;
        height: auto;
        border: none;
        margin-top: 10px;
        text-align: center;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .content-course {
      padding: 20px;
      width: auto;
      height: auto;
      .course-time {
        width: auto;
        .time-show {
          width: 70%;
          .time-show-item {
            width: 50%;
            font-size: 14px;
            padding-right: 5px;
            .el-divider {
              display: none;
            }
            .item-top {
              height: 0;
            }
          }
        }
      }
    }
    .content-culture {
      padding: 10px;
    }
    .content-honor {
      .honor-show {
        width: auto;
        li {
          width: auto;
          height: auto;
          margin-left: 0;
          list-style: none;
          border: none;
          text-align: center;
          img {
            width: 90%;
          }
        }
      }
      .honor-big-img {
        .el-dialog {
          width: 100%;
        }
      }
    }
    .content-team {
      width: 100%;
      padding: 0;
    }
  }
}
</style>